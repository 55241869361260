.nav-wizard > li {
    float: left;
    margin-bottom: 10px;
}
.nav-wizard > li > a {
    position: relative;
    background-color: #f9f9f9;
    color: #333333;
    height: 40px;
}
.nav-wizard .col {
    padding: 0;
    text-align: center;
}
.nav-wizard > li > a .badge {
    margin-left: 3px;
    color: #f9f9f9;
    background-color: #868686;
}
.nav-wizard > li:not(:first-child) > a {
    padding-left: 34px;
}
.nav-wizard > li:not(:first-child) > a:before {
    width: 0px;
    height: 0px;
    border-top: 20px inset transparent;
    border-bottom: 20px inset transparent;
    border-left: 20px solid #ffffff;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
}
.nav-wizard > li:not(:last-child) > a {
    margin-right: 6px;
}
.nav-wizard > li:not(:last-child) > a:after {
    width: 0px;
    height: 0px;
    border-top: 20px inset transparent;
    border-bottom: 20px inset transparent;
    border-left: 20px solid #f9f9f9;
    position: absolute;
    content: "";
    top: 0;
    right: -20px;
    z-index: 2;
}
.nav-wizard > li:first-child > a {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.nav-wizard > li:last-child > a {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.nav-wizard > li > a:focus {
    background-color: #f9f9f9;
}
.nav-wizard > li.done:hover > a,
.nav-wizard > li:hover > a {
    background-color: #d5d5d5;
}
.nav-wizard > li.done:hover > a:before,
.nav-wizard > li:hover > a:before {
    border-right-color: #d5d5d5;
}
.nav-wizard > li.done:hover > a:after,
.nav-wizard > li:hover > a:after {
    border-left-color: #d5d5d5;
}
.nav-wizard > li.done > a {
    background-color: #e2e2e2;
}
.nav-wizard > li.done > a:before {
    border-right-color: #e2e2e2;
}
.nav-wizard > li.done > a:after {
    border-left-color: #e2e2e2;
}
.nav-wizard > li.active > a,
.nav-wizard > li.active > a:hover,
.nav-wizard > li.active > a:focus {
    color: #ffffff;
    background-color: #868686;
}
.nav-wizard > li.active > a:after {
    border-left-color: #868686;
}
.nav-wizard > li.active > a .badge {
    color: #868686;
    background-color: #ffffff;
}
.nav-wizard > li.disabled > a {
    color: #777777;
}
.nav-wizard > li.disabled > a:hover,
.nav-wizard > li.disabled > a:focus {
    color: #777777;
    text-decoration: none;
    background-color: #f9f9f9;
    cursor: default;
}
.nav-wizard > li.disabled > a:before {
    border-right-color: #f9f9f9;
}
.nav-wizard > li.disabled > a:after {
    border-left-color: #f9f9f9;
}
.nav-wizard.nav-justified > li {
    float: none;
}
.nav-wizard.nav-justified > li > a {
    padding: 10px 15px;
}
@media (max-width: 767px) {
    .nav-wizard.nav-justified > li > a {
        border-radius: 4px;
        margin-right: 0;
    }
    .nav-wizard.nav-justified > li > a:before,
    .nav-wizard.nav-justified > li > a:after {
        border: none !important;
    }
    .nav-wizard > li:first-child > a {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .nav-wizard > li:last-child > a {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}
