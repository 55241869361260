@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import "bootstrap-nav-wizard";
@import "bootstrap-col-5ths";

.panel-heading {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}

/* sitzplan */

#seatplan {
    position: relative;
    margin: auto;
}

.seatplan-wrapper {
    border: 1px dotted #999999;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.seat,
.misc-item {
    background-color: #dddddd;
    color: #333333;
    border-radius: 4px;
    border: 1px solid #aaaaaa;
    font-style: normal;
    font-weight: normal;
    text-align: center;

    /*transition: all 0.9s;
    -moz-transition: all 0.9s;
    -ms-transition: all 0.9s;
    -webkit-transition: all 0.9s;*/

    /*-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;*/
}

#seatplan .seat,
.seatcategorys-legend .seat,
#seatplan .misc-item {
    position: absolute;
    display: inline-block;
}

#seatplan .seat,
.seatcategorys-legend .seat {
    width: 17px;
    height: 17px;
    line-height: 17px;
    font-size: 13px;
}

#seatplan .seat {
    cursor: pointer;
}

#seatplan .misc-item {
    background-color: #F1F1F1;
    color: #8E8E8E;
    border-color: #D2D2D2;
    text-align: center;
}

#seatplan .misc-item span {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
}
#seatplan .misc-item.small-text span {
    font-size: 12px;
}

.seat:hover {
    background-color: #aaaaaa;
    color: #000000;

    /*transition: all 0.1s;
    -moz-transition: all 0.1s;
    -ms-transition: all 0.1s;
    -webkit-transition: all 0.1s;*/
}


.seat.cat1 { background-color: #F9FFDB; color: #333333; border-color: #BEBF8C; }
.seat.cat2 { background-color: #C9D5FF; color: #333333; border-color: #97A3FF; }
.seat.cat3 { background-color: #D1FFD7; color: #333333; border-color: #6AB55C; }
.seat.cat4 { background-color: #FFD095; color: #333333; border-color: #A5907E; }

#seatplan .seat.cat1:hover { background-color: #FFEB88; border-color: #A3A537; }
#seatplan .seat.cat2:hover { background-color: #9BB0FB; border-color: #5361CE; }
#seatplan .seat.cat3:hover { background-color: #88FB97; border-color: #56984A; }
#seatplan .seat.cat4:hover { background-color: #FF9B35; border-color: #755C47; }

.seat.selecting {
    cursor: wait;
    cursor: progress;
}
.seat.selecting.cat1, .seat.selected.cat1 { background-color: #FFEB88; color: #5F5F5F; border-color: #A3A537; }
.seat.selecting.cat2, .seat.selected.cat2 { background-color: #9BB0FB; color: #263169; border-color: #5361CE; }
.seat.selecting.cat3, .seat.selected.cat3 { background-color: #88FB97; color: #236D22; border-color: #56984A; }
.seat.selecting.cat4, .seat.selected.cat4 { background-color: #FF9B35; color: #714E23; border-color: #755C47; }

#seatplan .seat.reserved {
    cursor: not-allowed;
}

.seat.reserved.cat1, .seat.reserved.cat2, .seat.reserved.cat3, .seat.reserved.cat4 { background-color: #F1F1F1; color: #888888; border-color: #CCCCCC; }

.seatcategorys-legend {
    margin-top: 10px;
}
.seatcategorys-legend ul {
    list-style-type: none;
    padding-left: 10px;
}
.seatcategorys-legend ul li {
    /*font-size: 17px;*/
}
.seatcategorys-legend .seat {
    position: static;
    vertical-align: middle;
}
.seatcategory {
    padding-left:5px;
}
.seatcategorys-legend-title {
    border-bottom: 1px solid #D0D0D0;
    margin-bottom: 5px;
}

.selected-seats {
    list-style-type: none;
    padding:0;
    margin:0;
}
.selected-seats .selected.seat {
    float: left;
    line-height: 14px;
    color: #000000;
    margin: 6px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    padding: 4px;
}

.selected-seats .selected.seat.placeholder {
    border: 1px solid #BBBBBB;
    background: #ffffff;
    color: #A9A9A9;
    border-radius: 4px;
}

.selected-seats .deselect-ticket {
    color: #606060;
    cursor: pointer;
}
.selected-seats .deselect-ticket:hover {
    color: #000000;
}

.event-dropdown .dropdown-menu li {
    /*padding-bottom: 5px;*/
}
.event-dropdown .dropdown-menu li a {
    font-size: 16px;
    line-height: 1.1;
}
.event-dropdown .dropdown-menu li a small {
    font-size: 14px;
    color: #777;
}

.actions {
    padding-bottom: 10px;
    padding-top: 10px;
}
.actions-col {
    padding-bottom: 10px;
}


body.login {
    padding-bottom: 40px;
    background-color: #eeeeee;
}

#loginbox {
    max-width: 400px;
    margin: 0 auto;
    margin-top: 30px;
}
#loginbox .input-group {
    margin-bottom: 15px;
}

.login-alerts .alert {
    margin-top: 15px;
    margin-bottom: 0;
}

.tg-body .page-header {
    margin-top: 0;
}

.order-confirm .order-summary {
    margin-top: 20px;
}

.order-confirm .order-sum-row {
    margin-top: 10px;
    font-size: 16px;
}

.order-confirm .selected-seats .selected.seat {
    float: none;
    margin: 0;
}
.order-confirm .selected-seats li {
    margin-bottom: 14px;
}

.row-more-bottom-space {
    margin-bottom: 15px;
}
